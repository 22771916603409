import Vue from 'vue'
import Component from 'vue-class-component'
import isEmpty from 'lodash/isEmpty'
import Lset from 'lodash/set'
import Lget from 'lodash/get'

import apiClient from '@/lib/unlogin/store/apiclient'
import { createErrorNotification, createNotification, createSuccessNotification } from '@/lib/unnotificationsqueue'

import Layout from '@/apps/core/components/Layout'
import sections from '@/apps/dido/views/defaultNavigationDrawerSections'
import BudgetDetailFilters from '@/apps/deimos/components/BudgetDetailFilters/BudgetDetailFilters.vue'
import HierarchiesPivotTableBase from '@/apps/core/components/HierarchiesPivotTableBase/HierarchiesPivotTableBase.vue'
import BreadcrumbsField from '@/apps/core/components/forms/BreadcrumbsField'
import { RESPONSE_LEVEL } from '@/variables'
import { unNumberFmt } from '@/lib/uncrudtable/mixins/filters'

@Component({
  components: {
    Layout,
    BudgetDetailFilters,
    HierarchiesPivotTableBase,
    BreadcrumbsField
  },
  props: {
    id: {
      type: [String, Number],
      description: 'Passed from the router parameter: either "new" or the budget id',
      required: true
    }
  }
})

class BudgetDetail extends Vue {
  sections = sections
  breadcrumbItems = [
    { text: 'DELEGATIONS LIST', disabled: false, name: 'BudgetList' },
    { text: 'DELEGATION', disabled: true }
  ]
  showChart = false
  budgetInstance = null
  isLoadedSelection = false
  loading = false
  selectedDistributorHierarchyElement = ''
  selectedBudgetConceptHierarchyElement = ''

  tableConfiguration = {
    columnFields: {},
    /** TODO: Fix this limit=0 */
    cellsEndpoint: `/budget-lines/?budget=${this.id}&limit=10000`,
    rowsEndpoints: [],
    columnsEndpoints: [],
    rowIdentifier: 'distributor_hierarchy_element',
    columnIdentifier: 'budget_hierarchy_element',
    rowLabel: 'Distributor',
    /**
     * To be called by the HPT-Data's orderedRows to determine whether a cell is editable
     * @param pivotTableData {Object} HPT-Data "this" instance
     * @param rowHierarchyElement {Object} Row hierarchy element that the cell belongs to
     * @param cell {Object} The cell instance
     * @returns {boolean}
     */
    isCellEditable: (pivotTableData, rowHierarchyElement, cell, column) => {
      let isBottomOfHierarchy = rowHierarchyElement.level_depth === pivotTableData.rowsHierarchyDepthRange.max
      let isNotTotal = !['promotions__kbd1', 'promotions__kbd2'].includes(column)
      return isBottomOfHierarchy && isNotTotal
    },
    customChartData: (pivotTableData) => {
      let chartData = { labels: [], datasets: [] }
      let secondGeneration = pivotTableData.orderedRows.filter(row => row.level_depth === pivotTableData.rowsHierarchyDepthRange.min)
      chartData.datasets = secondGeneration.map(row => ({ label: row.name, backgroundColor: '#f87979', data: [] }))
      pivotTableData.headers
        .filter(header => Object.keys(pivotTableData.columnFields).includes(header.value))
        .forEach((header, headerIndex) => {
          chartData.labels[headerIndex] = header.text
          secondGeneration.forEach((row, rowIndex) => {
            chartData.datasets[rowIndex].data[headerIndex] = row[header.value] ? row[header.value].amount : 0
          })
        })
      return chartData
    },
    /**
     * for each field you want to customize format , define an object with the element's value formatter (arrow func) and a suffix (Which can be empty)
     */
    customCellFormatters: {
      amount: {
        fmt: v => unNumberFmt(v / 1000, 0)
      },
      'materialized.iya_alt': {
        fmt: v => unNumberFmt(v, 0)
      },
      'materialized.iya': {
        fmt: v => unNumberFmt(v, 0)
      },
      'materialized.diff_iya': {
        fmt: v => unNumberFmt(v * 100, 1),
        suffix: '%'
      },
      'materialized.euro_m_alt': {
        fmt: v => unNumberFmt(v / 1000, 0)
      },
      'materialized.bts': {
        fmt: v => unNumberFmt(v / 1000, 0)
      },
      'materialized.percentage_giv': {
        fmt: v => unNumberFmt(v * 100, 1),
        suffix: '%'
      }
    },
    /**
     * Custom transformation when editing a cell's content
     * @param field {string} The field being customised/transformed
     * @param cellValue
     * @returns value {number}
     */
    customCellEdition: (field, cellValue) => {
      if (field === 'amount') {
        return cellValue * 1000
      } else {
        return cellValue
      }
    }
  }

  /**
   * Pivot table configuration prop: endpoints, identifiers and labels
   * NOTE: MOVE TO OPTIONS if this requires further customisation (i.e. Role-based parametrization)
   * @returns {Object}
   */
  get pivotTableConfig () {
    return this.tableConfiguration
  }

  /**
   * Gets whether the budget exists as an instance in the DB (From route parameter)
   * @returns {boolean}
   */
  get isNewBudget () {
    return this.id === 'new'
  }

  /**
   * Prop that controls when to show the table contents (HPT-Data), as to postpone its lifecycle methods
   * @returns {boolean}
   */
  get hideTable () {
    return this.isNewBudget || !this.budgetInstance || isEmpty(this.pivotTableConfig) ||
      !(this.pivotTableConfig.rowsEndpoints.length && this.pivotTableConfig.columnsEndpoints.length)
  }

  async mounted () {
    this.generatePivotTableColumnFields()
    if (!this.isNewBudget) {
      try {
        let res = await apiClient.get(`/budgets/${this.id}/`)
        this.budgetInstance = res.data
      } catch (error) {
        if (error.response && error.response.data && error.response.data.detail === 'Not found.') {
          this.$router.push({ name: 'BudgetDetail', params: { id: 'new' } })
          await this.$store.dispatch('addNotification', createErrorNotification(this.$t('general.notFoundIdElement')))
        } else {
          await this.$store.dispatch('addNotification', createErrorNotification(this.$t('general.basicError')))
        }
      }
    }
    this.isLoadedSelection = true
  }

  /**
   * Sets the columnFields to tell the HPT-Data which columns are to be displayed
   */
  generatePivotTableColumnFields () {
    this.tableConfiguration.columnFields['sales__giv'] = [
      { label: 'EuroM (€)', value: 'amount', editable: true },
      { label: 'IYA', value: 'materialized.iya', editable: true },
      { label: 'EuroM (actuals)', value: 'materialized.euro_m_alt', editable: false },
      { label: 'IYA (actuals)', value: 'materialized.iya_alt', editable: false },
      { label: 'BTS', value: 'materialized.bts', editable: false }
    ]
    let defaultColumnExternalIds = [
      'promotions__kbd1',
      'promotions__kbd1__kbd1_scanner',
      'promotions__kbd1__kbd1_shopper',
      'promotions__kbd1__kbd1_distribution_drive',
      'promotions__kbd2',
      'promotions__kbd2__kbd2_corporate_plans',
      'promotions__kbd2__kbd2_distribution_drive',
      'promotions__kbd2__kbd2_variable',
      'promotions__kbd2__kbd2_isf',
      'promotions__kbd2__kbd2_distribution_drive'
    ]
    defaultColumnExternalIds.forEach(columnId => {
      this.tableConfiguration.columnFields[columnId] = [
        { label: 'EuroM (€)', value: 'amount', editable: true },
        { label: 'GIV (%)', value: 'materialized.percentage_giv', editable: true },
        { label: 'IYA', value: 'materialized.iya', editable: true },
        { label: 'Diff IYA', value: 'materialized.diff_iya', editable: false },
        { label: 'EuroM (BE)', value: 'materialized.euro_m_alt', editable: false },
        { label: 'IYA (BE)', value: 'materialized.iya_alt', editable: false },
        { label: 'BTS', value: 'materialized.bts', editable: false }
      ]
    })
  }

  /**
   * Event handler when applying new filters: Updates endpoint URLs and refreshes the HPT based on detected changes
   * @param filters {Object} properties that modify the endpoints
   */
  onApplyFilters ({ kbdType, delegationBy, channel }) {
    let changedRowsEndpoints = this.updateRowsEndpoints({ delegationBy, channel })
    let changedColumnsEndpoints = this.updateColumnsEndpoints({ kbdType })
    this.tableConfiguration.cellsEndpoint = `/budget-lines/?budget=${this.id}&limit=10000&dhe=${this.selectedDistributorHierarchyElement}&bche=${this.selectedBudgetConceptHierarchyElement}`
    let changedProperty = (changedColumnsEndpoints && changedRowsEndpoints) ? 'all'
      : (!changedColumnsEndpoints && changedRowsEndpoints) ? 'row'
        : (changedColumnsEndpoints && !changedRowsEndpoints) ? 'column' : ''
    this.$refs.hierarchiesPivotTableBaseComponent.refreshData(changedProperty)
  }

  /**
   * Event handler for "@doneLoading", to stop loaders after HPT endpoints are fetched
   */
  onTableDoneLoading () {
    this.loading = false
  }

  /**
   * Detects changes to the DelegationType/Channel filters and modifies its endpoints accordingly
   * @param value: object with selected delegationBy
   * @returns {boolean} Whether the URL changed or not, as to refresh the proper dimensions
   */
  updateRowsEndpoints (value) {
    let oldDistributorFilter = this.selectedDistributorHierarchyElement
    if (value.delegationBy === 'Category') {
      this.selectedDistributorHierarchyElement = 'category'
      this.tableConfiguration.rowsEndpoints = [{
        url: '/distributor-hierarchy-levels/delegation__total_spain/hierarchy-elements/?depth=2',
        responseField: 'data'
      }]
    } else if (value.channel) {
      if (value.channel === -1) {
        this.selectedDistributorHierarchyElement = 'channel'
        this.tableConfiguration.rowsEndpoints = [{
          url: `/distributor-hierarchy-levels/delegation__channel_2/hierarchy-elements/?depth=1`,
          responseField: 'data'
        }]
      } else {
        this.selectedDistributorHierarchyElement = value.channel
        this.tableConfiguration.rowsEndpoints = [{
          url: `/distributor-hierarchy-levels/delegation__channel_2/hierarchy-elements/${value.channel}/?depth=1`,
          responseField: 'data'
        }]
      }
    } else {
      return false
    }
    return oldDistributorFilter !== this.selectedDistributorHierarchyElement
  }

  /**
   * Detects changes to the KBD-Type filter and modifies its endpoints accordingly
   * @param value: object with selected kbdType id
   * @returns {boolean} Whether the URL changed or not, as to refresh the proper dimensions
   */
  updateColumnsEndpoints (value) {
    let oldBudgetConceptFilter = this.selectedBudgetConceptHierarchyElement
    this.selectedBudgetConceptHierarchyElement = value.kbdType
    this.tableConfiguration.columnsEndpoints = [
      {
        url: `/budget-concept-hierarchy-levels/sales__type/hierarchy-elements/sales__giv/?depth=0`,
        responseField: 'data'
      },
      {
        url: `/budget-concept-hierarchy-levels/promotions__sd/hierarchy-elements/${value.kbdType}/?depth=1&usedInBudgets=true`,
        responseField: 'data.children'
      },
      {
        url: `/budget-concept-hierarchy-levels/promotions__sd/hierarchy-elements/${value.kbdType}/?depth=0&usedInBudgets=true`,
        responseField: 'data'
      }
    ]
    return oldBudgetConceptFilter !== value.kbdType
  }

  /**
   * Creates or updates the budget with its attributes passed from the selection component
   * @param event {string} Either 'generate' or 'update'
   * @param body {Object} - Budget attributes
   * @returns {Promise<void>}
   */
  async saveBudget ({ event, body }) {
    this.loading = true
    let isNewBudget = event === 'generate'
    try {
      let res = await apiClient.post('/budgets/', { ...body, currency: 1 })
      this.budgetInstance = res.data
      await this.$store.dispatch('addNotification', createSuccessNotification(
        isNewBudget ? this.$t('dialogs.successCreation') : this.$t('dialogs.successUpdate')
      ))
      if (isNewBudget) {
        this.tableConfiguration.cellsEndpoint = `/budget-lines/?budget=${res.data.id}&limit=10000&dhe=${this.selectedDistributorHierarchyElement}&bche=${this.selectedBudgetConceptHierarchyElement}`
        this.$router.push({ name: 'BudgetDetail', params: { id: res.data.id } })
      }
    } catch (error) {
      await this.$store.dispatch('addNotification', createErrorNotification(this.$t('general.basicError')))
    } finally {
      this.loading = false
    }
  }

  /**
   * Makes an UPDATE request to change a budget-line's content and refreshes full table with its new changes
   * @param fullInstance
   * @returns {Promise<void>}
   */
  async onSaveCell (fullInstance) {
    try {
      let cellBody = {}
      if (fullInstance.elementsChanged.length) {
        fullInstance.elementsChanged.forEach(changedField => {
          Lset(cellBody, changedField, Lget(fullInstance, changedField))
        })
        let patchResponse = await apiClient.patch(`/budget-lines/${fullInstance.id}/?updated_budget_lines=true`, cellBody)
        await this.$store.dispatch('addNotification', createNotification(this.$t('dialogs.savedNewValues'), RESPONSE_LEVEL.SUCCESS))
        this.doRefreshUpdatedCells(patchResponse)
      } else {
        await this.$store.dispatch('addNotification', createNotification('No data was modified', 'info'))
        this.$refs.hierarchiesPivotTableBaseComponent.undoUpdate(fullInstance.id)
      }
      this.$refs.hierarchiesPivotTableBaseComponent.isUpdateFinished()
    } catch (error) {
      let message = error.response && error.response.data && error.response.data.message
      let level = error.response && error.response.data && error.response.data.level
      await this.$store.dispatch('addNotification', createNotification(message || this.$t('dialogs.errorWhenSaving'), level || RESPONSE_LEVEL.ERROR))
      this.$refs.hierarchiesPivotTableBaseComponent.isUpdateFinished()
      this.$refs.hierarchiesPivotTableBaseComponent.undoUpdate(fullInstance.id)
    }
  }

  /**
   * Since the response doesn't include the actually modified object in the "updated_budget_lines" list
   *  (but is the rest of the response data), it must be included as to tell the HPT-Data to refresh it
   * @param patchResponse {Object} With the PATCH response containing the updated_budget_lines
   */
  doRefreshUpdatedCells (patchResponse) {
    let modifiedCell = Object.assign({}, patchResponse.data)
    delete modifiedCell.updated_budget_lines
    let updatedCells = [ modifiedCell, ...patchResponse.data.updated_budget_lines ]
    this.$refs.hierarchiesPivotTableBaseComponent.refreshUpdatedCells(updatedCells)
  }
}

export default BudgetDetail
