import Vue from 'vue'
import Component from 'vue-class-component'
import { Watch } from 'vue-property-decorator'
import isEqual from 'lodash/isEqual'

import apiClient from '@/lib/unlogin/store/apiclient'
import { createErrorNotification } from '@/lib/unnotificationsqueue'

import { dateToDateRange } from '@/apps/core/helpers/utils'
import { validationMixin } from '@/apps/core/mixins/validations'
import DateRangePickerInField from '@/apps/core/components/forms/DateRangePickerInField/DateRangePickerInField.vue'
import moment from 'moment'
import { sendBoundsFixer } from '@/apps/dido/helpers/boundsChecker'

@Component({
  components: {
    DateRangePickerInField
  },
  mixins: [
    validationMixin
  ],
  props: {
    budgetInstance: {
      type: Object,
      default: () => null
    },
    isNewBudget: {
      type: Boolean,
      required: true
    },
    loading: {
      type: Boolean,
      required: true
    },
    isLoadedSelection: {
      type: Boolean,
      default: true
    }
  }
})

class BudgetDetailFilters extends Vue {
  panel = [0]
  loadingSelection = false

  id = ''
  name = ''
  startDate = null
  endDate = null
  bounds = '[)'
  delegationBy = null
  kbdType = null
  category = null
  channel = null
  appliedFilters = {}
  showChartText = true

  delegationByOptions = ['Category', 'Channel']
  kbdTypeOptions = []
  categoryOptions = []
  channelOptions = []

  kbdTypeUrl = '/budget-concept-hierarchy-levels/promotions__sd/hierarchy-elements/'
  categoryUrl = '/product-hierarchy-levels/localdelegation__subsector/hierarchy-elements/'
  channelUrl = '/distributor-hierarchy-levels/delegation__channel_2/hierarchy-elements/'

  /**
   * Fetches KBD-Type, Category & Channel options from respective endpoint
   */
  mounted () {
    this.loadingSelection = true
    if (this.isNewBudget) {
      this.startDate = '2020-07-01'
      this.endDate = '2021-06-30'
      this.kbdType = 168
      this.delegationBy = 'Category'
    }
    Promise.all([
      apiClient.get(this.kbdTypeUrl),
      apiClient.get(this.categoryUrl),
      apiClient.get(this.channelUrl)
    ]).then(([kbdResponse, categoriesResponse, channelsResponse]) => {
      this.kbdTypeOptions = kbdResponse.data
      this.categoryOptions = categoriesResponse.data
      this.channelOptions = channelsResponse.data
    }).catch(() => {
      this.$store.dispatch('addNotification', createErrorNotification(this.$t('general.basicError')))
    }).finally(() => {
      this.loadingSelection = false
    })
  }

  /**
   * Checks 'delegationBy' to enable/disable the Channel selector
   * @returns {boolean}
   */
  get isChannelEnabled () {
    return this.delegationBy === 'Channel'
  }

  /**
   * Computes form object used as request body when generating a new budget
   * @returns {Object}
   */
  get selectionValues () {
    return {
      name: this.name,
      period: dateToDateRange(this.startDate, this.endDate),
      props: {
        delegation_by: this.delegationBy,
        kbd_type: this.kbdType,
        category: this.category.id,
        category_label: this.category.name,
        channel: this.channel
      }
    }
  }

  /**
   * Enables button when budget is not yet instantiated or when the description/dates are changed
   * TODO: Remove method - Button should always be enabled since changes to a Budget instance won't be allowed
   * @returns {boolean}
   */
  get disableGenerateButton () {
    if (this.isNewBudget || !this.budgetInstance) {
      return false
    }
    let period = this.budgetInstance.period && JSON.parse(this.budgetInstance.period)
    if (this.budgetInstance.name !== this.name ||
      (period && period.lower !== this.startDate) ||
      (period && period.upper !== this.endDate)
    ) {
      return false
    }
    return true
  }

  /**
   * Enables the "Apply Filters" button when a filter from the applied filters is modified
   * @returns {boolean}
   */
  get disableApplyFiltersButton () {
    let currentFilters = {
      kbdType: this.kbdType,
      delegationBy: this.delegationBy,
      channel: this.channel
    }
    return isEqual(currentFilters, this.appliedFilters) && !(this.delegationBy === 'Channel' && !this.channel)
  }

  get buttonText () {
    if (this.showChartText) {
      return 'Show Chart'
    } else {
      return 'Show Table'
    }
  }

  /**
   * Watcher callback (budgetInstance)
   * When the budget instance gets fetched, assigns its fields to the component variables
   */
  @Watch('budgetInstance', { deep: true })
  refreshForm () {
    let period = this.budgetInstance.period && JSON.parse(this.budgetInstance.period)
    this.id = this.budgetInstance.id
    this.name = this.budgetInstance.name
    this.bounds = period && period.bounds
    this.startDate = period && period.lower
    this.endDate = period && period.upper
    if (this.bounds === '[)' && this.endDate) this.endDate = moment(this.endDate).subtract(1, 'day').format('YYYY-MM-DD')
    this.kbdType = this.budgetInstance.props && this.budgetInstance.props.kbd_type
    this.delegationBy = this.budgetInstance.props && this.budgetInstance.props.delegation_by
    this.category = this.budgetInstance.props && this.budgetInstance.props.category
    this.channel = this.budgetInstance.props && this.budgetInstance.props.channel
    this.onApplyFilters()
  }

  /**
   * Watcher callback (isChannelEnabled)
   * TODO: Define if field is to be cleared or not
   * Clears 'channel' component variable when its selector is disabled
   * @param value {string} 'isChannelEnabled' computed property
   */
  // @Watch('isChannelEnabled')
  resetChannel (value) {
    if (!value) {
      this.channel = null
    }
  }

  /**
   * Event handler for the "Apply Filters" button: emits event with selected filters
   */
  onApplyFilters () {
    this.appliedFilters = {
      kbdType: this.kbdType,
      delegationBy: this.delegationBy,
      channel: this.channel
    }
    this.$emit('applyFilters', this.appliedFilters)
  }

  showCharts () {
    this.$emit('showChart')
    this.showChartText = !this.showChartText
  }

  /**
   * Emits 'save' event with respective body and event depending on creation/update
   */
  onSaveSelection () {
    if (this.$refs.form.validate()) {
      let event = this.isNewBudget ? 'generate' : 'update'
      let body = this.selectionValues
      body = sendBoundsFixer(body, ['period'])
      this.$emit('save', { event, body })
    }
  }
}

export default BudgetDetailFilters
